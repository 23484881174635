<template>
<div>

    <div class="text-center mt-5" v-if="cargando">
        <div class="spinner-border text-primary spinner-border-sm" >
            <span class="sr-only">Loading...</span>
        </div>
        <br>
        Cargando ...
    </div>

    <div class="row mb-5" v-if="!cargando">

        <div class="col-md-10 offset-md-1">
 
                <div class="py-3">
                    <span class="h2 titulosg ">
                        <strong>{{datospublicacion.titulo}}</strong>
                    </span>
                    <div class="text-secondary">
                        {{datospublicacion.resumen}}
                    </div>
                
                    <div class="mb-3 mt-3">
                        <span class="p-2 me-2 rounded text-capitalize">
                            <i class="far fa-calendar-alt me-2"></i> <strong class="me-2">Fecha:</strong> 
                            {{formatoFechaSimple(datospublicacion.fechainicio)}}
                        </span>
                    </div>
                </div>
       
                    <!-- <div v-if="datospublicacion.fotoportada != null" :style="'background-image: url('+ archivospublicos +'/contenidos/'+ datospublicacion.fotoportada +'); height: 300px;  background-size: 100% '">
                    </div> -->

                <img :src="archivospublicos+'/contenidos/'+datospublicacion.fotoportada" class="img-fluid" style="width: 100%;">
            
                <div class="p-5 bg-white">                
                    <span v-html="datospublicacion.texto"></span>

                    <div class="mt-3 pt-3 mb-3">
                        <strong> <i class="fas fa-paperclip fa-lg"></i> Archivos </strong>
                    </div>
                    <div class="mb-3 mb-2" v-for="item in datospublicacion.archivos" :key="item.idcontenidoarchivos">
                            <i class="fas fa-file-alt  fa-lg text-secondary "></i>
                            {{item.descripcionarchivo}}  <br> <a :href="archivospublicos+'/contenidos/'+item.archivofisico" target="blank" > {{item.archivofisico}}</a>
                    </div>              
                </div>
 
        </div>
    </div>


<!-- Modal Archivos -->
  <div class="modal fade" id="modalarchivo" tabindex="-1" aria-labelledby="modalarchivoLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalarchivoLabel">Archivo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

                    <span v-html="archivoview"> </span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import { ref, onMounted, getCurrentInstance  } from 'vue'
import axios from 'axios'
import moment from 'moment'

import { useRoute, useRouter } from 'vue-router'

export default {
    props: ['id'],
    setup(props) {

        const router = useRouter()
        const route = useRoute()

        const cargando = ref(true)
        const mostrar = ref(false)
        
        const datospublicacion = ref({})
        const iddocumento = ref()
        const documentoacargar = ref(null)
        const descripciondocumento = ref(null)

        const archivoview = ref(null)

        const consultarpublicacion = () => {
            axios.get('/api/infopublicacion/'+props.id).then( response => {

                datospublicacion.value=response.data
                iddocumento.value=response.data.idcontenido
                cargando.value = false;

            }).catch( error => console.log(error))
        }

        const getImage = (event) => {
              documentoacargar.value = event.target.files[0];
        }        
        
        const cargardocumentos = () => {
            
            mostrar.value = true

            let data = new FormData();
            data.append('idcontenido', props.id)
            data.append('descripcion', descripciondocumento.value)
            data.append('documento', documentoacargar.value)

            axios.post('/api/cargardocumentospublicacion', data ).then( response => {

                console.log(response.data)

                if(response.data !== "Error"){
                    swal("Bien!", "El archivo ha sido cargado correctamente!", "success") 
                }else{
                    swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");     
                }
                
                descripciondocumento.value=''
                mostrar.value = false
                document.getElementById("cargararchivo").reset();
                
                consultarpublicacion()

            }).catch( error => console.log(error))
        }
 
 
        const mostrarimagen = (extrecibida, archivo) => {
            
            axios.get('/api/getdocument/'+archivo).then(response => {
                
                let ventanaarchivos = new bootstrap.Modal(document.getElementById('modalarchivo'))

                let extensionesmodal = ['jpg', 'png', 'jpeg', 'gif']
                if(extensionesmodal.includes(extrecibida)){
                    
                    archivoview.value = '<img src="'+response.data+'" class="img-fluid">'
                    // <iframe :src="archivoview" class="border" style="width: 100%; " frameborder="0"></iframe> 
                    ventanaarchivos.show()

                }
                if(extrecibida=="pdf"){

                    archivoview.value = '<object data="'+response.data+'" class="w-100"  style="min-height: 500px;"></object>'
                    ventanaarchivos.show()

                }

                if(extrecibida != "pdf" && !extensionesmodal.includes(extrecibida)){
                    window.open(response.data)
                }

                
            }).catch( error => {

                swal(":(", "No es posible mostrar el archivo", "error")    

            })
            
        }
 

        onMounted(() => {
            consultarpublicacion()  
        })

        const formatoFecha = (date) => {
            return moment(date).format('MMMM D [de] YYYY, h:mm:ss a');
        }

        const formatoFechaSimple = (date) => {
            return moment(date).format('MMMM D [de] YYYY');
        }

        return {
            mostrar,
            cargando,
            datospublicacion,
            documentoacargar,
            descripciondocumento,
            archivoview,

            //Funciones
            formatoFecha,
            formatoFechaSimple,
            cargardocumentos,
            mostrarimagen,
        }

    }
}
</script>

<style>

</style>
